import React from 'react';
import GoHome from '../Components/GoHome';
import Settings from '../Components/Settings';
import PageTitle from '../Components/PageTitle';
import SoundControll from '../Components/SoundControll';
//import UserIcon from './UserIcon';
import { useLocation } from 'react-router-dom'
import LangSelectHomeScreen from './LangSelectHomeScreen';


const PageRegControllers = ( { title, atHome, noSound, hide, noPageHeader } ) => {
 
  const { pathname } = useLocation()
  const noLangIcons = ['/exercise_repited','/training','/exercise_completed','/exercise_rpt']
  const noHomeIcon = ['/', '/training']
  
  return (
    <>
     {!noPageHeader ? (
      <PageTitle
       title={`NO Typo | ${title}`}
      />
     ) : ('')}
      
     
      {noHomeIcon.includes(pathname) ? '' : <GoHome />}
      {noLangIcons.includes(pathname) ? '' : <LangSelectHomeScreen />}
     
      {!atHome && <Settings hide={hide}/>}
      {!noSound ? <SoundControll /> : ''}
    </>
  )
}

export default PageRegControllers    
//{/* {!atHome ? (!noHome && <GoHome />) : <UserIcon />} */}
import React, { useState } from 'react';
import c from './Form.module.css';
import Captcha from '../Components/Captcha/Captcha';
import { Form, FormControl, Button } from 'react-bootstrap';

// import { FormattedMessage } from 'react-intl';
// import defineMessages from '../Lang/languages/messages';

const ContactForm = () => {

 const [notChecked, setNotChecked] = useState(true);
 const [btnText, setBtnText] = useState('Please enter CAPTCHA symbols into the field');
 const [btnTextSmall, setBtnTextSmall] = useState('Please enter CAPTCHA symbols');
 const [btnColor, setBtnColor] = useState('outline-light');

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [message, setMessage] = useState('');
 //const [] = useState('');

const disableBtn = (event) => {
   // event.preventDefault();
   //https://s-n.top/feedback.php
   //location.href='https://s-n.top/feedback.php?page=downloadWin&email='+email;
    setNotChecked(true);
    setBtnColor('outline-light');
    setBtnText('Please enter CAPTCHA symbols into the field');
       setBtnTextSmall('Please enter CAPTCHA symbols');
    setTimeout(() => {
        setName('');
        setEmail('');       
        setMessage('');
    }, 2000);
    
 };

 const allowSending = (data) => {
     if (data === false) {
         setNotChecked(false);
         setBtnText('S E N D');
         setBtnTextSmall('S E N D');
         setBtnColor('outline-success');
     } else if (data === true) {
        disableBtn();
     }
    
 };
 

  return (
  <Form action="feedback.php" method="post" onSubmit={(event) => disableBtn(event)}>
    <div className={c.form_container}>
        <div className={c.form_top_portion}>
            <div className={c.form_col1}>
              <FormControl 
                  placeholder="Your Name" 
                  name="name"                 
                  value={name} 
                  type="text"
                  className={c.form_control}
                  onChange={(event) => {setName(event.target.value)}}
                  required
              /> 
              
               <FormControl 
                  placeholder="Contact Email:" 
                  name="email"
                  value={email} 
                  type="email"
                  className={c.form_control}
                  onChange={(event) => {setEmail(event.target.value)}}
              /> 
              
            </div>
            <div className={c.form_col2}>
             <FormControl 
                   placeholder="Message:" 
                   name="message"
                   value={message} 
                   as="textarea" 
                   rows={4}
                   type="text"
                   className={c.form_control}
                   onChange={(event) => {setMessage(event.target.value)}}
                   required
              /> 
              
           
            </div>
        </div>

         <div className={`mt-3 ${c.form_lower_portion}`}>
            <div className='w-100'>
                <Captcha 
                size={32}
                action={allowSending}
                />
                
            </div>
            <Button 
                variant={btnColor}
                id='send-btn-modal'
                type='submit'
                // onClick={disableBtn}
                className={`mb-4 mt-3 ${c.rounded_btn}`}
                disabled={notChecked}
            >
                <span className={c.btn_text}>{btnText}</span>
                <span className={c.btn_text_small}>{btnTextSmall}</span>
            </Button>
        </div>

    </div>
    </Form>
  )
}

export default ContactForm
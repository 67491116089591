import React, { 
    useEffect, useState
}  from 'react';
import s from './action_btn.module.css'
import c from '../views/home_screen.module.css';
import { useHistory } from "react-router-dom";
import { motion } from 'framer-motion/dist/framer-motion';
// import {playSound} from '../Components/PlaySound';
// import Schedric from '../assets/Schedric.mp3';
import PageRegControllers from '../Components/PageRegControllers';
import {ArrowRightIcon} from '../Components/Icons'
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import Settings from '../Components/Settings';
import { Button } from 'react-bootstrap'

import  { useSelector } from 'react-redux'

const HomeScreen = () => {

const triger = useSelector((state) => state.triger.triger)


// useEffect(() => {
//     if(schedric && !noRepeat && soundOff !== 'yes') {
//        // console.log('go', schedric, noRepeat);
//         setNoRepeat(true);
//         playSound(Schedric);
//         setTimeout(() => setNoRepeat(false),40000); 
//         setTimeout(() => setSchedric(false),35000);                  
//       }
// // eslint-disable-next-line 
// },[schedric, soundOff]);

const history = useHistory();

const link = (data, load) => {         
        localStorage.setItem('toHighest', load);
    history.push(data);
};
const linkToHighest = (data, load) => {
    localStorage.setItem('exerciseCount', parseInt(lessonExercPassed?.slice(1, 3)));
    localStorage.setItem('lessonCount', lessonExercPassed?.slice(0, 1));
    localStorage.setItem('lessonComplete', 'no');   
      localStorage.setItem('toHighest', load);
  history.push(data);
};
const startLessonOne = () => {
   localStorage.setItem('lessonStarted', false);
   localStorage.setItem('lessonComplete', 'no');
   localStorage.setItem('exerciseCount', 1);
   localStorage.setItem('lessonCount', 1);
   link(`/training`, 'no');
};
//const allLessons = () => {
    // localStorage.setItem('lessonStarted', false);
    // localStorage.setItem('lessonComplete', 'no');
    // localStorage.setItem('exerciseCount', 1);
    // localStorage.setItem('lessonCount', 1);
//     link(`/all_lessons`);
//  };
const lessonExercPassed = localStorage.getItem('lessonExcercPassed');
//console.log(lessonExercPassed);

const [pageHeader, setPageHeader] = useState('Твоя Супер Здібність');
useEffect(() => { 
   
  if (triger === 'en') {      
    setPageHeader('Your Super Power');   
  } else if (triger === 'ua') {
     setPageHeader('Твоя Супер Здібність');  
  }  else if (triger === 'ar') {
      setPageHeader('حقق قواك العظمى');   
  }    
}, [triger]);
const [showHome, setShowHome] = useState(false)

  return (
    <div className={c.home_screen_wrapper}>
      
        <Settings 
          home={true}
          setShowHome={setShowHome} 
          showHome={showHome}
        />
      <PageRegControllers title={`${pageHeader}`} atHome={true}/>

       <div className={c.logo_container}>
            <motion.div 
                
                whileHover={{
                    originX: 0,
                    scale: 1.03,
                    textShadow: '0px 0px 2px rgb(255,255,255)',
                    color: '#f8e112',                        
                }}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                <span className={`mr-3 ${c.italic_txt}`} >NO TYPO</span> -  <FormattedMessage id='logo' defaultMessage={defineMessages.logo} /> 
                          
            </motion.div>
                    <Button variant='success'  onClick={() => lessonExercPassed === null ? (startLessonOne()) : (linkToHighest(`/training`, 'yes'))} className={` ${c.rouned_btn} ${s.action_btn}`} >
                        <span className={c.note_notice_txt}>
                        {lessonExercPassed === null ?
                         (<FormattedMessage id='headerStartBtn1' defaultMessage={defineMessages.headerStartBtn1} />) :
                        ( <FormattedMessage id='headerStartBtn2' defaultMessage={defineMessages.headerStartBtn2} />)
                        }
                        </span>
                    </Button>    
        </div>

        
    <div className={c.home_screen_body_holder}>      
        <div className={c.home_screen_options_container}>
            {lessonExercPassed === null ? (
                <motion.div 
                    onClick={() => startLessonOne()} 
                    className={`mt-3 ${c.link}`}                   
                    whileHover={{                       
                        scale: 1.1,
                        textShadow: '0px 0px 8px rgb(255,255,255)',
                        color: '#f8e112',                        
                    }}
                    transition={{ type: 'spring', stiffness: 300 }}                   
                 >
                  
                 <FormattedMessage id='startTraining' defaultMessage={defineMessages.startTraining} /> {ArrowRightIcon()} 
                </motion.div>
            ) : (
                <motion.div 
                    onClick={() => linkToHighest(`/training`, 'yes')} 
                    className={`mt-3 ${c.link}`}                   
                    whileHover={{                       
                        scale: 1.1,
                        textShadow: '0px 0px 8px rgb(255,255,255)',
                        color: '#f8e112',                        
                    }}
                    transition={{ type: 'spring', stiffness: 300 }}                   
                 >
                    
                    <FormattedMessage id='goToTraining' defaultMessage={defineMessages.goToTraining} />   {ArrowRightIcon()}            
                </motion.div>
            )}
         
            <motion.div 
             onClick={() => link(`/all_lessons`, 'no')}
                   className={`mt-3 ${c.link}`}
                   whileHover={{
                    scale: 1.1,
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    color: '#f8e112',                        
                }}
                transition={{ type: 'spring', stiffness: 300 }}
            >               
                <FormattedMessage id='allSuperPowers' defaultMessage={defineMessages.allSuperPowers} /> {ArrowRightIcon()}
            </motion.div>
            
   

            <motion.div 
                   onClick={() => link(`/contact_form`, 'no')}
                   className={`mt-3 ${c.link}`}
                   whileHover={{
                    scale: 1.1,
                    textShadow: '0px 0px 8px rgb(255,255,255)',
                    color: '#f8e112',                        
                }}
                transition={{ type: 'spring', stiffness: 300 }}
            >
                <FormattedMessage id='contactUs' defaultMessage={defineMessages.contactUs} /> {ArrowRightIcon()}
            </motion.div>
       </div>
       <div className={c.home_screen_body_txt}>         
         <h3>
           <FormattedMessage id='gainPower' defaultMessage={defineMessages.gainPower} /><br/>
           <FormattedMessage id='rightPlace' defaultMessage={defineMessages.rightPlace} />           
         </h3> 
        <div className={c.home_screen_article_container}>

            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
                <h4>
                 <FormattedMessage id='howTo' defaultMessage={defineMessages.howTo} />
                </h4>
                <FormattedMessage id='forTouchType' defaultMessage={defineMessages.forTouchType} /> <br/>
                <FormattedMessage id='practiceReg' defaultMessage={defineMessages.practiceReg} />                      
              </div> 
             
                    
            </div>

            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
                  <h4>
                   <FormattedMessage id='homeRow' defaultMessage={defineMessages.homeRow} />                  
                  </h4>
                   <FormattedMessage id='yourFingures' defaultMessage={defineMessages.yourFingures} />               
                  <br/><b><FormattedMessage id='homeRowPos' defaultMessage={defineMessages.homeRowPos} /></b> <br/>
                  <FormattedMessage id='alwaysStart' defaultMessage={defineMessages.alwaysStart} /> 
                </div>
              <div className={c.img_wrapper}>
                <div className={c.home_position_pic}/>               
              </div>
             
            </div>

            <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
               <h4>
                <FormattedMessage id='finMo' defaultMessage={defineMessages.finMo} />
               </h4>
                <FormattedMessage id='finMoPar' defaultMessage={defineMessages.finMoPar} />
                <br/><br/>
               <h4>
                <FormattedMessage id='tySpd' defaultMessage={defineMessages.tySpd} />
               </h4>
                 <FormattedMessage id='tySpdPar1' defaultMessage={defineMessages.tySpdPar1} /><br/>
                 <FormattedMessage id='tySpdPar2' defaultMessage={defineMessages.tySpdPar2} /><br/>
                 <FormattedMessage id='tySpdPar3' defaultMessage={defineMessages.tySpdPar3} /><br/>
              </div>
              <div className={c.img_wrapper}>
                <div className={c.touch_typing_pic}/>
                 
              </div>
            </div> 

              <div className={c.home_screen_article_containe_row}>
              <div className={c.home_screen_article}>
               <h4>
               <FormattedMessage id='TrainAny' defaultMessage={defineMessages.TrainAny} />
               </h4>
               <FormattedMessage id='TrainAnyPar1' defaultMessage={defineMessages.TrainAnyPar1} /><br/>
               <FormattedMessage id='TrainAnyPar2' defaultMessage={defineMessages.TrainAnyPar2} /><br/>
               <FormattedMessage id='TrainAnyPar3' defaultMessage={defineMessages.TrainAnyPar3} />      
              </div>
              <div className={c.img_wrapper}>
                <div className={c.no_net_pic}/>
              </div>
            </div>

          </div>
         </div>
      </div>
    </div>
    
  )
}

export default HomeScreen



 
    //   {/* {soundOff !== 'yes' ? (
    //       <motion.div 
    //                onClick={() => setSchedric(true)}
    //                className={`mt-3 ${c.link}`}
    //                whileHover={{
    //                 scale: 1.1,
    //                 textShadow: '0px 0px 8px rgb(255,255,255)',
    //                 color: '#f8e112',                        
    //             }}
    //             transition={{ type: 'spring', stiffness: 300 }}
    //         >
    //             <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
    //            <br/>
    //            <FormattedMessage id='schedric2' defaultMessage={defineMessages.schedric2} /> 
    //         </motion.div>
    //   ) : (
    //     <div className={c.no_sound}>
    //         <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
    //          <br/>
    //         <FormattedMessage id='schedric3' defaultMessage={defineMessages.schedric3} />             
    //     </div>
    //   )} */}
       
          


    //         {/* <motion.div 
    //                onClick={() => link(`/lesson_completed`, 'no')}
    //                className={`mt-3 ${c.link}`}
    //                whileHover={{
    //                 scale: 1.1,
    //                 textShadow: '0px 0px 8px rgb(255,255,255)',
    //                 color: '#f8e112',                        
    //             }}
    //             transition={{ type: 'spring', stiffness: 300 }}
    //         >
    //            Lesson End Screen 
    //         </motion.div> */}

  //   <div className={c.lang_intro_wrapper}>
  //   <h4>
  //    <FormattedMessage id='multLangInt' defaultMessage={defineMessages.multLangInt} /> 
  //   </h4>
  //   <div className={c.lang_intro_description} onClick={() => setShowHome(!showHome)}>
  //    <FormattedMessage id='chkStg' defaultMessage={defineMessages.chkStg} /> {gearIcon(25)} <FormattedMessage id='toChoose' defaultMessage={defineMessages.toChoose} />                  
  //   </div>
   
  //   <div className={c.flags_container}>
  //       <div className={c.flag_item1} onClick={() => context.selectLangString('en')}/>
  //       <div className={c.flag_item2} onClick={() => context.selectLangString('ua')} />
  //       <div className={c.flag_item3} onClick={() => context.selectLangString('ar')}/>
  //   </div>  
  // </div>
import React, { 
                useState, 
                useEffect, 
                useContext 
              } from 'react';
import { useHistory } from "react-router-dom";
import PostLessonNotification from '../views/PostLessonNotification';
import { ExercContext } from '../Context/ExercContext';
import { handleStarsCount,  handleLessExercId } from '../Functions';
import PageRegControllers from '../Components/PageRegControllers';

import { playSound } from '../Components/PlaySound';
import sound from '../assets/bells.mp3';
import fanfars from '../assets/fanfars.mp3';
import c from '../views/post_lesson.module.css';

import  { useSelector } from 'react-redux'

const ExercEndController = () => {

  const history = useHistory();
  const volumeOn = useSelector((state) => state.sound.soundOn)

  const { 
          mistakesCount, setMistakesCount,
          starsCount, setStarsCount,
          setCursPos,
          typesPerMinute, 
          lessonCount, setLessonCount,
          exerciseCount, setExerciseCount,
          setLessonStarted,
          lessonExercPassed, setLessonExercPassed,
          lesson,
          //numberOfExerc,
        }  = useContext(ExercContext);

const triger = useSelector((state) => state.triger.triger)
const keyBoardLayOut = useSelector((state) => state.layout.layout)
///////////////////////////////////////////////////////////////////
/// Розраховуємо та записуємо NoTypos
const totalNoTypos = localStorage.getItem('totalNoTypos') ? localStorage.getItem('totalNoTypos') : 0;
const [noTyposEarned, setNoTyposEarned] = useState(0);
const totalTypes = localStorage.getItem('exrsLenght');

 useEffect(() => {
  let data = (+totalTypes - +mistakesCount) * (+typesPerMinute / 100); 
  setNoTyposEarned(data.toFixed(0));  
 },[totalTypes, mistakesCount, typesPerMinute]);

 useEffect(() => {
  let data = +totalNoTypos + +noTyposEarned; 
  localStorage.setItem('totalNoTypos', data);
 // eslint-disable-next-line
 },[noTyposEarned]);
/////////////////////////////////////////////////////////////////


  useEffect(() => {
    handleStarsCount(mistakesCount, setStarsCount);
  // eslint-disable-next-line
  }, []);

  const keyFunction = (event) => {    
    if(event.key === 'Enter') {           
        handleContinue();       
    } 
 };
  useEffect(() => {
      document.addEventListener('keydown', keyFunction);  
    return () => {
      document.removeEventListener('keydown', keyFunction);
    }        
// eslint-disable-next-line 
}, []);

useEffect(() => {
if(+mistakesCount === 0 && typesPerMinute > 50) {
  volumeOn && playSound(fanfars);
} else {
  volumeOn && playSound(sound);
} 
// eslint-disable-next-line
}, []);


// const lessonResults = localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`) 
//                                             ? 
//                                             JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`)) 
//                                             : 
//                                             [];


//const [newResultsData, setNewResultsData] = useState('');
///////////////// Фільтруємо старі данні, та Записуємо нові. Переходимо до нового уроку


  const handleContinue = () => { 
    
       setCursPos(0);
    if(+exerciseCount < 9) { 
          if(+lessonExercPassed < parseInt(`${lessonCount}0${+exerciseCount + 1}`)) {      
            localStorage.setItem('lessonExcercPassed', `${lessonCount}0${+exerciseCount + 1}`);
            setLessonExercPassed(`${lessonCount}0${+exerciseCount + 1}`);
          }
      } else if(+exerciseCount === 10) {  
          localStorage.setItem('lessonExcercPassed', `${+lessonCount + 1}01`);
          setLessonExercPassed(`${+lessonCount + 1}01`);
      } else {
        
          if(+lessonExercPassed < parseInt(`${lessonCount}${+exerciseCount + 1}`)) {     
            localStorage.setItem('lessonExcercPassed', `${lessonCount}${+exerciseCount + 1}`);
            setLessonExercPassed(`${lessonCount}${+exerciseCount + 1}`);          
          }    
      }  
  
    if(+exerciseCount === +lesson[+lessonCount - 1].length) {   
      setExerciseCount(1);
      localStorage.setItem('exerciseCount', 1);
      localStorage.setItem('lessonComplete', 'no');
      setLessonCount(prev => +prev + 1);
      localStorage.setItem('lessonCount', +lessonCount + 1);   
    } else {   
      setExerciseCount(prev => +prev + 1);
      localStorage.setItem('exerciseCount', +exerciseCount + 1);
      localStorage.setItem('lessonComplete', 'no');
    }
      setMistakesCount(0);
      localStorage.setItem('mistakeCounter', 0);
/////////////////////////////////////////////////////////////////////
      

      let data = {id: handleLessExercId(lessonCount, exerciseCount), stars: starsCount, tpm: typesPerMinute,}; 
  console.log(data);
     // setNewResultsData(data);
     //setExistingLocStorData(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`));
     let existingLocStorData = JSON.parse(localStorage.getItem(`${keyBoardLayOut}Lesson${lessonCount}Res`));
  console.log(existingLocStorData);
     if(existingLocStorData === null) {
      localStorage.setItem(`${keyBoardLayOut}Lesson${lessonCount}Res`, JSON.stringify([data])); 
      history.push('/training');
     } else {
        let checkAgainst = +data.id?.slice(0, 1) * 100 + 99;    
        let recordsSorted = existingLocStorData.filter(obj => obj.id !== data.id)                        
                           .filter(obj => +obj.id < +checkAgainst)
                           .filter(obj => +obj.id > +checkAgainst-100);
        let newDataToSave = [...recordsSorted, data]
                           .sort((a, b) => a.id.localeCompare(b.id));
     console.log(newDataToSave);
        localStorage.setItem(`${keyBoardLayOut}Lesson${lessonCount}Res`, JSON.stringify(newDataToSave)); 
      history.push('/training');
     }
  };


  const handleRptExrc = () => {
    setCursPos(0);
    setMistakesCount(0);
    setLessonStarted(true); 
    localStorage.setItem('lessonComplete', 'no');  
    history.push('/training');  
  };
  //
  const [pageHeader, setPageHeader] = useState('Твій Результат');
  useEffect(() => {    
    if (triger === 'en') {      
      setPageHeader('Your Result');
    } else if (triger === 'ua') {
       setPageHeader('Твій Результат');
    }  else if (triger === 'ar') {
        setPageHeader('Your Result');
    }    
  }, [triger]);

  return (
    <div className={c.wrapper}>
    <PageRegControllers title={`${pageHeader}`} hide={true}/>
    
      <PostLessonNotification
        typesPerMin={typesPerMinute}
        mistakesCount={mistakesCount}
        starsCount={starsCount} 
        handleRptExrc={handleRptExrc}
        handleContinue={handleContinue}
      />
     
    </div>
  )
}

export default ExercEndController
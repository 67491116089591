import React, {useContext} from 'react'
import { Context } from '../Lang/LanguageProvider';
import c from '../views/home_screen.module.css'

const LangSelectHomeScreen = () => {

    const context = useContext(Context); 

  return (
    <div className={c.abs_lang_select}>
       <div className={c.flags_container}>
            <div className={c.flag_item1} onClick={() => context.selectLangString('en')}/>
            <div className={c.flag_item2} onClick={() => context.selectLangString('ua')} />
            <div className={c.flag_item3} onClick={() => context.selectLangString('ar')}/>
        </div>
    </div>
  )
}

export default LangSelectHomeScreen
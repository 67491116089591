//import React, { useEffect } from 'react';
import c from './components.module.css';
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion/dist/framer-motion';
import useBg from '../Hooks/useBg';
import {  StarFillIcon  } from './Icons';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import { v4 as uuidv4 } from "uuid"




const ShadeOverScreen = (props) => {

//   const keyFunction = (event) => {    
//     if(event.key === 'Enter') {           
//       props.handleUnPause()       
//     } 
//  };

//   useEffect(() => {
//     document.addEventListener('keydown', keyFunction);  
//   return () => {
//     document.removeEventListener('keydown', keyFunction);
//   }        
// // eslint-disable-next-line 
// }, []);

const shadeOver = {
  visible: {
      y: 0, x: 0, transition: {duration: 0.8},
      scale: 1, 
      //transition: {duration: 0.7},
  },
  hidden: {
      y: -1500, x: 0, transition: {duration: 1},
      scale: 0
  }
};

const {vertLineBg} = useBg();
const star = StarFillIcon(18, `var(--yellow-txt)`); 
  return (
    <> 
      <motion.div className={c.shade_scr}
                  variants={shadeOver}
                  animate='visible' 
                  initial='hidden'
                  exit='hidden'
      >
        <div className={c.shade_scr_txt_container}>
           <span className={c.shade_scr_txt0}>
            {props.rpt ? (
              <FormattedMessage id='youAreRpt' defaultMessage={defineMessages.youAreRpt} />               
            ) : (
              <FormattedMessage id='youAreTraining' defaultMessage={defineMessages.youAreTraining} />  
            )}                    
           </span><br/>

           <div className={c.shade_scr_sub_container}>
                 <div className={vertLineBg}/>      
             <div className={c.shade_scr_txt_item}> 
            
              <span className={c.shade_scr_txt1}>
              <FormattedMessage id='superPower' defaultMessage={defineMessages.superPower} /> {props.lessonCount}
              </span> 
              <span className={c.shade_scr_txt}>{props.header[props.lesson - 1]}</span><br/>
             </div>
           </div>

            <div className={c.shade_scr_sub_container}>
                 <div className={vertLineBg}/>      
                 <div className={c.shade_scr_txt_item}>            
                  <span className={c.shade_scr_txt2}>
                    <FormattedMessage id='exerc' defaultMessage={defineMessages.exerc} /> {props.exerciseCount}
                  </span>
                  <span className={c.shade_scr_txt}> 
                  {props.desc[props.lesson - 1][props.exercise - 1][1]} - {props.desc[props.lesson - 1][props.exercise - 1][2]}
                  </span>
                </div>
           </div>  

           {props.rpt ? (
              <div className={c.shade_scr_sub_container}>
                 <div className={vertLineBg}/>      
                 <div className={c.shade_scr_txt_item}>            
                  <span className={c.shade_scr_txt2}> 
                    <FormattedMessage id='bestRes' defaultMessage={defineMessages.bestRes} /> 
                  </span>
                  <span className={c.shade_scr_txt}> 
                  {props.fiveStars.map((starNum) => (props.prevRes.stars >= starNum && <span key={uuidv4()} className='ml-3 pb-2'>{star}</span>))}<br/>
                <span >{props.prevRes.tpm} <span >
                 <FormattedMessage id='tpm' defaultMessage={defineMessages.tpm} /> 
                </span></span> 
                  </span>
                </div>
              </div>
            ) : ('')}

        </div>

        <div className={c.shade_scr_btn_container}>
            <Button variant='outline-light' className={c.rounded_btn} onClick={() => props.handleUnPause(props.cursPos, props.setTimeCount)}>
              <span className='px-4'>
              <FormattedMessage id='continue' defaultMessage={defineMessages.continue} />
              </span>
            </Button>
        </div>
        
      </motion.div>
    </>
   
  )
}

export default ShadeOverScreen
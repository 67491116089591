import React from 'react'
import ContactForm from '../Components/ContactForm'
import c from './contact_form.module.css'
import PageRegControllers from '../Components/PageRegControllers'

const ContactFormController = () => {
  return (
    <div className={c.form_container}>
       <PageRegControllers />
        <ContactForm />
    </div>
  )
}

export default ContactFormController
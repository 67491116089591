import { configureStore } from '@reduxjs/toolkit';
import  soundReducer  from './soundSlice';
import bgReducer from './bgSlice';
import keyBoardLayoutReducer from './keyBoardLayoutSlice';
import trigerReducer from './trigerSlice';

// export default configureStore({
//     reducer: {
//         rentList: rentReducer,
//         TOJobsList: TOJobsReducer,
//     }
// });

export default configureStore({
    reducer: {
       sound: soundReducer,
       bg: bgReducer,
       layout: keyBoardLayoutReducer,
       triger: trigerReducer
    }
});


